import { ADD_ITEM, EDIT_ITEM, REMOVE_ITEM } from "./action";

import KandaBhajiya from "../foodImages/kandaBhajiya.png"
import friedIdli from "../foodImages/babyFriedidli.png"
import chaknabox from "../foodImages/CakhnaBox.png"
import crispycorn from "../foodImages/Crispy corn bhel.png"
import ChilliChickenImg from "../Images/Chilli Chicken.png";

import PapadImg from "../Images/Papad.png";
import SaladImg from "../Images/Salad.png";
import PickelImg from "../Images/Pickel.png";
import SteamedRiceImg from "../Images/Steamed Rice.png";
import BreadImg from "../Images/Indian Breadbasket.png";
import CoffeeImg from "../Images/Coffee.png";
import SoftDrinksImg from "../Images/Soft Drinks.png";
import TetraPackImg from "../Images/Tetra Packet Juice.png";
import WaterImg from "../Images/Water.png";

import BudwiserImg from "../Images/Budweiser.png";

import WhiteWineImg from "../Images/White Wine.png";
import RedWineImg from "../Images/Red Wine.png";

import redlabel from "../Images/Red Label.png";

import bacardi from "../Images/bacardi.png";

import Tanqueray from "../Images/tanquerey.png";
import stolich from "../Images/stolich.png";


import vadapao from "../foodImages/DadarVadaPav.png"
import behlpuri from "../foodImages/juhubeachBhel.png"
import cheesechillytoast from "../foodImages/CheeseChilliToast.png"
import papad from "../foodImages/AplamChaplamPapadum.png"
import chineesebhel from "../foodImages/ChieneseBhel.png"
import shakarkand from "../foodImages/Shakarkand ki chaat.png"
import rajkachori from '../foodImages/Raj Kachori.png'
import crispycorn2 from "../foodImages/Crispy corn bhel.png"
import seafoodbhel from "../foodImages/Seafood bhel.png"
import chandnichowk from "../foodImages/Chandni chowk aloo chaat.png"
import chickencutlet from "../foodImages/Chicken cutlet.png"
import keemasamosa from "../foodImages/Bohri kheema samosa.png"
import kejriwal from "../foodImages/Egg kejriwal.png"
import chickenfry from "../foodImages/Bar baar chicken fry.png"
import chickentikka from "../foodImages/Peri peri chicekn tikka.png"
import Muttonburrah from "../foodImages/Mutton burrah.png" 
import peshawari from "../foodImages/Peshawari chapli kebab.png"
import baidaroti from "../foodImages/Chicken baida roti.png"
import bohrikeemapav from "../foodImages/Bohri Kheema pav.png"
import chooza69 from "../foodImages/Chooza 69.png"
import nadenchicken from "../foodImages/Naden chicken fry.png"
import Fauladipahadikebab from "../foodImages/Fauladi pahadi kebab.png"
import bhendi from "../foodImages/Bhendi bazaar seekh paratha.png"
import prawn from "../foodImages/Prawn kolbi fry.png"
import pratap from "../foodImages/Chef Pratap’s ghar ki bhendi.png"
import parsi from "../foodImages/Mushroom patio with PAV.png"
import dalmakhani from "../foodImages/Daal Makhani with butter naan.png"
import baap from "../foodImages/Baap ki dal with jeera rice.png"
import paneerlababdar from "../foodImages/Paneer lababdar with butter garlic naan.png"
import rajasthani from "../foodImages/Rajasthani curry pakora bowl with basmati rice.png"
import khichdi from "../foodImages/Dal Khichdi.png"
import parleG from "../foodImages/Parle G cheese cake.png"
import gajjar from "../foodImages/Gajjar halwa with kulfi ice cream.png"
import custard from "../foodImages/Super caramel custard.png"
import fruit from "../foodImages/Fruit platter.png"
import murghMussallam from "../foodImages/Murgh Mussallam 2.0.png"
import salli from "../foodImages/Salli chicken.png"



// ///// new food images 


const hide = "/Imagess/Calico/hide&seekh.png";
const tandoori = "/Imagess/Calico/tandoori.png";
const lollypop = "/Imagess/Calico/lollypop.png";
const paneerTikka = "/Imagess/Calico/paneerTikka.png";
const jal = "/Imagess/Calico/jalfrezi.png";
const noodles = "/Imagess/Calico/noodles.png";
const butter = '/Imagess/Calico/butterChicken.png';
const kadhai = "/Imagess/Calico/kadhaiPaneer.png";
const cheese = "/Imagess/Calico/gulabjamunCheesecake.png";


const mushroom = "/Imagess/Calico/mushroom.png";
const plant = "/Imagess/Calico/plantsPizza.png";
const kungfu = "/Imagess/Calico/kungFu.png";
const salad = "/Imagess/Calico/salad.png";

const add1 = "Imagess/Calico/blanc.png";
const add2 = "Imagess/Calico/blanc2.png";
const add3 = "Imagess/Calico/superier.png";
const add4 = "Imagess/Calico/clicquot.png";
const add5 = "Imagess/Calico/magnum.png";


const initialState = Object.freeze({
  Premium: {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },
      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
  Regular: {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },


      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
  Exclusive: {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },
      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
  "Ultra Exclusive": {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },
      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
  "Gerneral Reservations": {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },
      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
});

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        [action.payload.category]: [
          ...state[action.payload.category],
          action.payload.item,
        ],
      };

    case REMOVE_ITEM:
      return {
        ...state,
        [action.payload.category]: state[action.payload.category].filter(
          (item) => item.id !== action.payload.itemId
        ),
      };

    case EDIT_ITEM:
      return {
        ...state,
        [action.payload.category]: state[action.payload.category].map((item) =>
          item.id === action.payload.itemId ? action.payload.updatedItem : item
        ),
      };

    default:
      return state;
  }
};

export default menuReducer;